/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Badge from '../ui/badge/badge';

const WelcomeSection = ({ avatar }) => (
  <div
    className="container py-6 text-center"
    css={css`
      transition: all 800ms;
    `}
  >
    <div className="flex justify-center">
      <GatsbyImage image={avatar} className="rounded-full" alt="Avatar" />
    </div>
    <h1 className="font-bold mt-1">Hi, I'm Juvoni Beckford.</h1>
    <p
      className="font-display flex md:block flex-col items-center"
      style={{
        transition: 'color 0.2s ease-out, background 0.2s ease-out',
      }}
      css={css`
        color: var(--textNormal);
      `}
    >
      I'm a
      <Badge icon="square" iconTwo="terminal">
        Software Engineer
      </Badge>
      <Badge icon="laptop" iconTwo="brush">
        UI/UX Designer
      </Badge>
      <Badge icon="reader">Voracious Reader</Badge>
      <Badge icon="dumbbell">Fitness Enthusiast</Badge>
      <Badge icon="hands">Community Builder</Badge>
      <br className="hidden md:inline" />
      based in <span className="welcome-item about-city font-bold">New York City</span>
      <span className="hidden md:inline">.</span>
    </p>
    <div className="text-center font-display">
      <p>
        I'm interested in the meta-skill of “Learning How to Learn” and write about a disciplined
        approach to growth by developing a System &amp; Habit Oriented lifestyle for Lifelong
        Learning and Continuous Self-Improvement.
      </p>
      <p>
        I curate <Link to="/books">Books</Link>,<Link to="/category/systems">Technologies</Link>,
        Mental Models, Mindsets and Cognitive Biases for smarter and more self-aware living, seeing
        the world as it is, while bringing your visions to life.
      </p>
      <p>
        I work towards cultivating <strong>Discipline</strong>,<strong>Curiosity</strong>,{' '}
        <strong>Adaptability</strong>,<strong>Self-Awareness</strong> and <strong>Empathy</strong>,
        values I use to become more{' '}
        <strong>
          <a
            href="http://gettingstronger.org/2013/07/antifragile/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Antifragile
          </a>
        </strong>
        . Taking an engineering approach to turning scarcity into abundance.
      </p>
    </div>
  </div>
);

export default WelcomeSection;
