import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import nextId from 'react-id-generator';

export default function TextCurrentlyReading() {
  const data = useStaticQuery(graphql`
    {
      recentBooks: allReading(limit: 5, sort: { fields: frontmatter___date, order: DESC }) {
        nodes {
          frontmatter {
            title
          }
        }
      }
    }
  `);

  return (
    <div className="w-full md:w-1/4">
      <h2 className="mt-0 mb-6 text-center md:text-left text-primary-600 text-xl">
        Currently Reading
      </h2>
      <ul>
        {data.recentBooks.nodes.map((item) => {
          const { frontmatter } = item;
          return (
            <li key={nextId()} className="text-sm font-sans mt-2 list-decimal">
              {frontmatter.title}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
