/** @jsx jsx */
import { css, jsx } from '@emotion/react';

const SelectArticleSection = ({ children }) => (
  <div
    className="bg-primary-100 border-t border-b border-primary-200 py-6"
    css={css`
      background-color: var(--bgSection);
    `}
  >
    <div className="container flex flex-col md:flex-row md:justify-between">{children}</div>
  </div>
);

export default SelectArticleSection;
