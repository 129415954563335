/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import { ReadTimeBadge } from '../ui/badge/badge';
import Button from '../ui/button/button';

const FeaturedArticle = ({ background, slug, title, excerpt, date, timeToRead }) => {
  const image = getImage(background);
  const imgStack = ['linear-gradient(rgba(5,15,10,.7),rgba(5,15,10,.7))', image];
  return (
    <BgImage
      image={imgStack}
      className="border-top"
      css={css`
        &::after,
        &::before {
          background-position: center center;
          background-size: cover;
          background-attachment: fixed;
        }
      `}
      style={{ backgroundPosition: '', backgroundSize: '' }}
    >
      <div className="text-white">
        <div className="container py-12">
          <div className="flex flex-wrap flex-col md:flex-row items-center justify-start">
            <div className="w-full lg:w-1/2 lg:pr-6">
              <div>
                <p className="text-primary-500 font-bold font-sans uppercase mt-0">
                  <i className="far fa-bookmark  mr-3 " />
                  Featured
                </p>
                <h1 className="font-bold text-white">
                  <Link to={`${slug}`}>{title}</Link>
                </h1>
                <div className="">
                  <span className="mr-4 mb-1 inline-block font-sans text-sm"> {date}</span>
                  <ReadTimeBadge timeToRead={timeToRead} />
                </div>

                <p className="font-sans text-white text-sm">{excerpt}</p>
                <div className="actions">
                  <Button dark to={`${slug}`}>
                    Read
                  </Button>
                </div>
              </div>
            </div>
            <div className="hidden lg:block lg:w-1/2">
              <GatsbyImage
                image={background.childImageSharp.gatsbyImageData}
                alt={`Featured Article ${title}`}
                style={{
                  borderRadius: '.25rem',
                  margin: '0 auto',
                  // maxWidth: 1000,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </BgImage>
  );
};

export default FeaturedArticle;
