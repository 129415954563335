/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const RecentBooks = ({ recentBooks }) => {
  const recentBookContainer = {
    backgroundColor: 'var(--bg)',
    color: 'var(--textNormal)',
    transition: 'color 0.2s ease-out, background 0.2s ease-out',
  };
  const bookCardRecentStyle = {
    flex: '0 0 auto',
    padding: '25px 10px',
    transition: 'color 0.2s ease-out, background 0.2s ease-out',
    width: '225px',
  };

  const imageStyle = {
    borderRadius: '9px',
    margin: '0 auto',
    width: '125px',
  };
  return (
    <div className="bg-gray-100 border-t border-b border-gray-300 py-6" style={recentBookContainer}>
      <div className="items-center">
        <h2 className="mt-0 mb-6 ml-4 text-gray-500 text-2x text-center">
          <Link
            to="/books"
            className="no-underline hover:rounded p-2 border-b-4 border-primary-300 hover:bg-primary-300 hover:text-white"
            css={css`
              color: inherit;
              transition: all 0.1s cubic-bezier(0.33, 0.66, 0.66, 1);
            `}
          >
            Recent Books
          </Link>
        </h2>
        <div
          className="flex flex-no-wrap  lg:justify-center overflow-x-auto"
          css={css`
            -webkit-overflow-scrolling: touch;
            &::-webkit-scrollbar {
              display: none;
            }
          `}
        >
          {recentBooks.nodes.map((book) => (
            <Link to={book.frontmatter.slug} key={book.frontmatter.slug} className="py-4 px-2">
              <div
                className="h-84 rounded shadow hover:shadow-lg"
                style={bookCardRecentStyle}
                css={css`
                  color: var(--textNormal);
                  background-color: var(--bgRaised);
                `}
              >
                <div className="px-6">
                  {' '}
                  <GatsbyImage
                    image={book.frontmatter.image.childImageSharp.gatsbyImageData}
                    alt={book.frontmatter.title}
                    style={imageStyle}
                  />
                </div>
                <h4
                  className="text-center my-4 text-sm font-display text-gray-700"
                  css={css`
                    color: var(--textNormal);
                  `}
                >
                  {book.frontmatter.title.length > 75
                    ? book.frontmatter.title.split(':')[0]
                    : book.frontmatter.title}
                </h4>
              </div>
            </Link>
          ))}
          <div className="flex flex-col px-4 align-center h-auto justify-center items-center">
            <Link to="/books">
              <div
                className="flex items-center justify-center shadow hover:shadow-md h-16 w-16 bg-gray-200 hover:bg-gray-300 rounded-full"
                css={css`
                  color: var(--textNormal);
                  background: var(--bgRaised);
                `}
              >
                <i className="text-gray-500 fa fa fa-arrow-right" />
              </div>
            </Link>
            <p className="font-sans text-sm text-center">View All Books</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentBooks;
