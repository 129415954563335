/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { graphql } from 'gatsby';
import LazyLoad from 'react-lazyload';
import Layout from '../components/Layout';
import NewsletterForm from '../components/NewsletterFormSidebar';
import SEO from '../components/seo/seo';
import BlogPostCard from '../components/template/BlogPostCard';
import Categories from '../components/template/Categories';
import FeaturedArticle from '../components/template/FeaturedArticle';
import PageMenuCard from '../components/template/PageMenuCard';
import RecentArticles from '../components/template/RecentArticles';
import RecentBooks from '../components/template/RecentBooks';
import SelectArticleSection from '../components/template/SelectArticles';
import WelcomeSection from '../components/template/WelcomeSection';
import TextCurrentlyReading from '../components/ui/book/TextCurrentlyReadingWidget';
import Divider from '../components/ui/divider/divider';

export default function Index({ data }) {
  return (
    <Layout sticky>
      <SEO />
      <WelcomeSection avatar={data.thumbnail.childImageSharp.gatsbyImageData} />
      <NewsletterForm />
      <SelectArticleSection>
        <RecentArticles recentPosts={data.recentPosts} className="w-full md:w-1/4" />
        <Categories className="w-full md:w-1/4" />
        <TextCurrentlyReading />
      </SelectArticleSection>
      {data.featuredPosts.nodes[0] && (
        <LazyLoad height={500} offset={50} once>
          <FeaturedArticle
            background={data.featuredPosts.nodes[0].frontmatter.image}
            slug={data.featuredPosts.nodes[0].frontmatter.slug}
            title={data.featuredPosts.nodes[0].frontmatter.title}
            excerpt={data.featuredPosts.nodes[0].frontmatter.excerpt}
            date={data.featuredPosts.nodes[0].frontmatter.date}
            timeToRead={data.featuredPosts.nodes[0].fields.readingTime.text}
          />
        </LazyLoad>
      )}
      <LazyLoad height={500} offset={50} once>
        <RecentBooks recentBooks={data.recentBooks} />
      </LazyLoad>
      {data.featuredPosts.nodes[0] && (
        <LazyLoad height={475} offset={50} once>
          <FeaturedArticle
            background={data.featuredPosts.nodes[1].frontmatter.image}
            slug={data.featuredPosts.nodes[1].frontmatter.slug}
            title={data.featuredPosts.nodes[1].frontmatter.title}
            excerpt={data.featuredPosts.nodes[1].frontmatter.excerpt}
            date={data.featuredPosts.nodes[1].frontmatter.date}
            timeToRead={data.featuredPosts.nodes[1].fields.readingTime.text}
          />
        </LazyLoad>
      )}
      <div>
        <div className="py-2">
          <h2 className="text-center">Featured Pages</h2>
          {data.pages.nodes && (
            <LazyLoad height={550} offset={200} once>
              <div
                className="flex flex-no-wrap lg:justify-center overflow-x-auto"
                css={css`
                  -webkit-overflow-scrolling: touch;
                  &::-webkit-scrollbar {
                    display: none;
                  }
                `}
              >
                {data.pages.nodes.map((item) => (
                  <PageMenuCard key={item.frontmatter.slug} post={item} />
                ))}
              </div>
              <Divider position="bottom" className="w-2/3" />
            </LazyLoad>
          )}
        </div>
      </div>
      <div>
        <div className="container py-2">
          <h2 className="text-center">Popular Articles</h2>
          <LazyLoad height={1100} offset={200} once>
            <div className="flex flex-wrap  flex-row items-stretch justify-center row">
              {data.popularArticles.nodes.map((item) => (
                <BlogPostCard key={item.frontmatter.slug} post={item} />
              ))}
            </div>
          </LazyLoad>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    thumbnail: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "juvoni-thumbnail.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          quality: 90
          width: 150
          placeholder: BLURRED
          layout: FIXED
          formats: [AUTO, WEBP]
        )
      }
    }
    bgPattern: file(relativePath: { eq: "lines-bg-pattern.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, placeholder: BLURRED, layout: FIXED, formats: [AUTO, WEBP])
      }
    }
    recentPosts: allMdx(
      limit: 5
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        fields: { type: { eq: "BlogPost" } }
        frontmatter: { image: { relativePath: { ne: null } } }
      }
    ) {
      totalCount
      nodes {
        frontmatter {
          title
          slug
          date(formatString: "dddd, MMMM DD, YYYY")
          author
        }
      }
    }
    pages: allMdx(
      limit: 4
      filter: {
        fields: { type: { eq: "Content" } }
        frontmatter: {
          draft: { ne: true }
          slug: { in: ["/now", "/tools", "/bookmarks", "/best-books"] }
        }
      }
    ) {
      nodes {
        frontmatter {
          title
          subtitle
          date(formatString: "DD MMMM YYYY")
          excerpt
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
            }
          }
          slug
        }
        fields {
          readingTime {
            text
          }
        }
      }
    }
    popularArticles: allMdx(
      filter: {
        frontmatter: {
          slug: {
            in: [
              "/favorite-lessons-creativity-inc"
              "/habit-detective"
              "/the-stoic-reading-list"
              "/using-mint-com-for-monthly-and-annual-budgeting"
              "/print-ebook-audiobook"
              "/tale-of-the-student-hustle"
            ]
          }
        }
      }
    ) {
      nodes {
        frontmatter {
          title
          date(formatString: "DD MMMM YYYY")
          excerpt
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
            }
          }
          slug
          author
        }
        fields {
          readingTime {
            text
          }
        }
      }
    }
    featuredPosts: allMdx(
      limit: 2
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { image: { relativePath: { ne: null } }, feature: { eq: true } } }
    ) {
      totalCount
      nodes {
        frontmatter {
          title
          excerpt
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
            }
          }
          slug
          date(formatString: "dddd, MMMM DD, YYYY")
          author
        }
        fields {
          readingTime {
            text
          }
        }
      }
    }
    recentBooks: allBook(limit: 5, sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          title
          slug
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  }
`;
