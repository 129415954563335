/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import { memo } from 'react';

export default memo(({ post }) => {
  const pageCardStyle = {
    flex: '0 0 auto',
    width: '275px',
  };
  const image = getImage(post.frontmatter.image);
  const imgStack = ['linear-gradient(rgba(5,15,10,.1),rgba(5,15,10,.6))', image];
  return (
    <div className="md:mx-2">
      <div
        className="my-4 md:my-8 mx-4 rounded-lg hover:shadow-xl overflow-hidden"
        style={pageCardStyle}
      >
        <Link to={`${post.frontmatter.slug}`}>
          <BgImage image={imgStack}>
            <div
              className="p-4 flex-col text-white items-center text-center"
              css={css`
                min-height: 10rem;
                height: 100%;
              `}
            >
              {' '}
              <h3 className="font-bold font-display text-white">{post.frontmatter.title}</h3>
              <span className="font-display text-xs text-white">{post.frontmatter.subtitle}</span>
            </div>
          </BgImage>
        </Link>
      </div>
    </div>
  );
});
